<template>
	<v-textarea
			v-model="new_val"
			outlined
			@keydown="(e) => manageEvent(e)"
			:label="label ? label : ''"
			class="paragraph rounded-lg"
			dense
			:auto-grow="grow"
			hide-details
			overflow-hidden
			shaped
			:rows="grow ? '1' : null"
	/>
</template>

<script>
	export default {
		name: "TextAreaField",
		props: {value:{}, label:{}, grow: {default:false}, noEnter: {default: false}},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		methods:{
			manageEvent(event){
				if (event.key === "Enter" && this.noEnter){
					event.preventDefault()
				}
			}
		}
	}
</script>

<style scoped>

</style>